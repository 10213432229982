











































import { namespace } from "vuex-class";
import { Component } from "vue-property-decorator";
import { AUTH_STORE_NAME, AuthStoreGetters } from "@/store/auth.store";
import { email, required } from "vuelidate/lib/validators";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { mixins } from "vue-class-component";
import User from "@/models/User.model";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";
import {
  APPLICATION_STORE_NAME,
  ApplicationStoreActions,
  ApplicationStoreGetters,
} from "@/store/application.store";
import Application from "@/models/Application";
import { AxiosResponse } from "axios";
import EmployeeProfile from "@/components/EmployeeProfile.vue";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
  components: {
    EmployeeProfile,
    EmployeeRequestComponent: () =>
      import("@/components/Employee.request.component.vue"),
    EmployeeServiceCenterComponent: () =>
      import("@/components/Employee.service.center.component.vue"),
  },
})
export default class EmployeePortalRequestsView extends mixins(
  ErrorMessageHandlerMixin
) {
  @ApplicationStore.Action(ApplicationStoreActions.GET_APPLICATIONS)
  private getApplication!: (payload: {
    status?: ApplicationStatus
  }) => Promise<any | null>;

  @ApplicationStore.Action(ApplicationStoreActions.FETCH_NEWSLETTER_SUBSCRIBERS)
  private fetchSubscribers!: () => Promise<number>;

  /**
   * Action to Download the Newsletter User Sheet
   * @private
   */
  @ApplicationStore.Action(ApplicationStoreActions.DOWNLOAD_NEWSLETTER_SHEET)
  private downloadSubscriberSheet!: () => Promise<AxiosResponse>;

  /**
   * Getter to Retrieve the current logged in User
   * @private
   */
  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user!: User;

  private loading: boolean = false;

  /**
   * Getter to retrieve the current Number of Newsletter Subscribers
   * @private
   */
  @ApplicationStore.Getter(ApplicationStoreGetters.NUMBER_OF_SUBSCRIBERS)
  private numberOfSubscribers!: number;

  async created() {
    await this.getData();
    await this.fetchSubscribers();
  }

  private async getData() {
    try {
      this.loading = true;
      // logs in the user, checks if the user has the correct role
      const user = await this.getApplication({});
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.NETWORK_ERROR");
        }
      });
    } finally {
      this.loading = false;
    }
  }


  /**
   * On Click Listener for Excel Download
   * @private
   */
  private async onDownloadNewsletterClicked() {
    await this.downloadSubscriberSheet();
  }
}
