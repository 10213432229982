









































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import User from "@/models/User.model";
import EditUserComponent from "@/components/EditUser.component.vue";
import ChangePasswordComponent from "@/components/Authentication/ChangePassword.component.vue";

@Component({
  components: {
    EditUserComponent,
    ChangePasswordComponent
  }
})
export default class EmployeeProfile extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: true })
  public value!: number;

  /**
   * User Data to display
   */
  @Prop() user!: User;

  /**
   * Should the Edit User Dialog be shown?
   */
  showEditUserModal = false;

  /**
   * Should the Change Password Dialog be shown?
   */
  showChangePasswordModal = false;

  /**
   * closes the edit user dialog
   * @private
   */
  private onEditUserDialogClosed() {
    this.showEditUserModal = false;
  }

  /**
   * opens the edit user dialog
   * @private
   */
  private onEditPressed() {
    this.showEditUserModal = true;
  }

  /**
   * Closes the change password dialog
   * @private
   */
  private onChangePasswordDialogClosed() {
    this.showChangePasswordModal = false;
  }

  /**
   * Opens the change password dialog
   * @private
   */
  private onChangePasswordOpenPressed() {
    this.showChangePasswordModal = true;
  }
}
